import * as React from 'react';
import * as MovementStore from '../store/Movement';
import * as RobotScript from '../store/RobotScript';
import { Alert, Badge } from 'reactstrap';
import nipplejs from 'nipplejs';

type MovementProps =
  MovementStore.MovementState
  & RobotScript.RobotScriptState
  & typeof MovementStore.actionCreators
  & typeof RobotScript.actionCreators;

export class MovementPanel extends React.PureComponent<MovementProps> {

  private readonly nippleContainerRef: React.RefObject<HTMLDivElement>;
  private nippleManager: nipplejs.JoystickManager | undefined;
  private currentJoyStickNipple: nipplejs.Joystick | undefined;
  private prevMovPos: number[] = [0, 0];
  private prevJoystickPos: number[] = [0, 0];
  private stopTimeout: ReturnType<typeof setTimeout> | undefined;
  private gamepadButtonBounceGate: boolean = false;
  private gamePadButtonBounceTimeout: ReturnType<typeof setTimeout> | undefined;
  private controlLoopRunning: boolean = false;
  private readonly stopThreshold = 0.2;
  private readonly moveThreshold = 0.1;

  constructor(props: any) {

    super(props);
    this.nippleContainerRef = React.createRef<HTMLDivElement>();
    this.currentJoyStickNipple = undefined;
    this.handleMove = this.handleMove.bind(this);
    this.controlLoop = this.controlLoop.bind(this);
    this.move1Direction = this.move1Direction.bind(this);
    this.handleGamePadConnection = this.handleGamePadConnection.bind(this);
  }

  public handleMove(x: number, y: number): void {

    if (this.props.isSending)
      return;

    const drive = Math.sqrt(x * x + y * y);
    const prevDrive = Math.sqrt(this.prevMovPos[0] * this.prevMovPos[0] + this.prevMovPos[1] * this.prevMovPos[1]);

    // If change in move is less than move threshold ignore move unless we are transitioning into stop
    if (this.prevMovPos && !(drive < this.stopThreshold && prevDrive >= this.stopThreshold))
      if (Math.abs(this.prevMovPos[0] - x) < this.moveThreshold && Math.abs(this.prevMovPos[1] - y) < this.moveThreshold)
        return;

    this.prevMovPos = [x, y];

    if (drive < this.stopThreshold)
      this.props.requestMovement(MovementStore.Direction.Stop, 0, 0);
    else {

      const radian = Math.atan2(y, x);

      let leftSpeed = drive;
      let rightSpeed = drive;

      let direction = MovementStore.Direction.Forward;
      if (Math.abs(radian) < Math.PI / 4)
        direction = MovementStore.Direction.Right;
      else if (Math.abs(radian) > 3 * Math.PI / 4)
        direction = MovementStore.Direction.Left;
      else if (radian < 0)
        direction = MovementStore.Direction.Reverse;

      const variableSpeed = Math.abs(Math.abs(y) - Math.abs(x) + .2 * Math.abs(y)) * drive;

      if (x > .2)
        rightSpeed = variableSpeed;
      else if (x < -.2)
        leftSpeed = variableSpeed;

      this.props.requestMovement(direction, leftSpeed, rightSpeed);
    }
  }

  private move1Direction(direction: MovementStore.Direction): boolean {

    if (this.props.isSending)
      return false;
    switch (direction) {

      case MovementStore.Direction.Left:
        this.props.requestMovement(MovementStore.Direction.Left, 0, 0);
        if (this.currentJoyStickNipple)
          this.currentJoyStickNipple.setPosition(() => null, { x: -50, y: 0 });
        break;
      case MovementStore.Direction.Forward:
        this.props.requestMovement(MovementStore.Direction.Forward, 0, 0);
        if (this.currentJoyStickNipple)
          this.currentJoyStickNipple.setPosition(() => null, { x: 0, y: -50 });
        break;
      case MovementStore.Direction.Right:
        this.props.requestMovement(MovementStore.Direction.Right, 0, 0);
        if (this.currentJoyStickNipple)
          this.currentJoyStickNipple.setPosition(() => null, { x: 50, y: 0 });
        break;
      case MovementStore.Direction.Reverse:
        this.props.requestMovement(MovementStore.Direction.Reverse, 0, 0);
        if (this.currentJoyStickNipple)
          this.currentJoyStickNipple.setPosition(() => null, { x: 0, y: 50 });
        break;
    }

    if (this.stopTimeout)
      clearTimeout(this.stopTimeout);

    this.stopTimeout = setTimeout(() => {
      this.props.requestMovement(MovementStore.Direction.Stop, 0, 0);
      if (this.currentJoyStickNipple)
        this.currentJoyStickNipple.setPosition(() => null, { x: 0, y: 0 });
    }, 250);

    return true;
  }

  public componentDidMount() {

    const node = this.nippleContainerRef.current;
    if (!node)
      return;

    //window.addEventListener("resize", () => {

      if (!this.nippleManager) {
        this.nippleManager = nipplejs.create({
          zone: node,
          mode: 'static',
          color: 'black',
          position: { left: '50%', top: '50%' },
          size: 200,
          threshold: 10,
          restOpacity: 0.8
        });

        this.currentJoyStickNipple = this.nippleManager.get(0);
        if (!this.currentJoyStickNipple)
          this.currentJoyStickNipple = this.nippleManager.get(1);

        this.nippleManager.on('end', () => {

          this.props.requestMovement(MovementStore.Direction.Stop, 0, 0);
        });
        this.nippleManager.on('move', (evt, data) => {

          this.currentJoyStickNipple = data.instance;
          this.handleMove(data.vector.x, data.vector.y);
        });
        this.nippleManager.on('shown', (evt, data) => {

          this.currentJoyStickNipple = data.instance;
          window.dispatchEvent(new Event('resize'));
        });
      }
    //});

    document.addEventListener("keydown", ev => {

      if (ev.keyCode < 37 || ev.keyCode > 40)
        return true;

      switch (ev.keyCode) {
        case 37:
          this.move1Direction(MovementStore.Direction.Left);
          break;
        case 38:
          this.move1Direction(MovementStore.Direction.Forward);
          break;
        case 39:
          this.move1Direction(MovementStore.Direction.Right);
          break;
        case 40:
          this.move1Direction(MovementStore.Direction.Reverse);
          break;
      }

      ev.preventDefault();
      return false;
    });

    window.removeEventListener("gamepadconnected", this.handleGamePadConnection);
    window.addEventListener("gamepadconnected", this.handleGamePadConnection);

    window.addEventListener("gamepaddisconnected", (e) => {

      if (e instanceof GamepadEvent && e.gamepad.index === this.props.gamePadIndex)
        this.props.gamePadDisconnected();
      this.controlLoopRunning = false;
    });
  }

  private handleGamePadConnection(e: any) {

    if (e instanceof GamepadEvent)
      if (e.gamepad.axes.length > 0)
        this.props.gamePadConnected(e.gamepad.index);

    if (!this.controlLoopRunning) {
      this.controlLoopRunning = true;
      this.controlLoop();
    }
  }

  private controlLoop() {

    if (this.props.gamePadIndex === undefined)
      return;

    const gp: Gamepad | null = navigator.getGamepads()[this.props.gamePadIndex as number];

    if (!gp) {
      this.controlLoopRunning = false;
      return;
    }

    if (gp.buttons)
      gp.buttons.forEach((btn: GamepadButton, i) => {

        if (btn.pressed) {

          if (!this.gamepadButtonBounceGate) {

            this.gamepadButtonBounceGate = true;
            if (this.gamePadButtonBounceTimeout)
              clearTimeout(this.gamePadButtonBounceTimeout);

            this.gamePadButtonBounceTimeout = setTimeout(() => this.gamepadButtonBounceGate = false, 300);

            switch (i) {
              case 12: // Up
                this.move1Direction(MovementStore.Direction.Forward);
                break;

              case 13: //down
                this.move1Direction(MovementStore.Direction.Reverse);
                break;

              case 14: //left
                this.move1Direction(MovementStore.Direction.Left);
                break;

              case 15: //right 
                this.move1Direction(MovementStore.Direction.Right);
                break;

              case 0: //A
                this.props.requestRobotScript(0);
                break;

              case 1: // B
                this.props.requestRobotScript(1);
                break;

              case 2: //X
                this.props.requestRobotScript(2);
                break;

              case 3: // Y
                this.props.requestRobotScript(3);
                break;
            }
          }
        }
      });

    const x = Math.abs(gp.axes[0]) > Math.abs(gp.axes[2]) ? gp.axes[0] : gp.axes[2];
    const y = -(Math.abs(gp.axes[1]) > Math.abs(gp.axes[3]) ? gp.axes[1] : gp.axes[3]);
    if (this.currentJoyStickNipple)
      if (Math.abs(this.prevJoystickPos[0] - x) > 0.01 || Math.abs(this.prevJoystickPos[1] - y) > 0.01) {
        this.prevJoystickPos = [x, y];
        this.currentJoyStickNipple.setPosition(() => this.handleMove(x, y), { x: x * 100, y: -y * 100 });
      }

    requestAnimationFrame(this.controlLoop);
  }

  public render() {
    return (
      <React.Fragment>
        <div style={{ position: 'relative', left: '0', top: '0', width: '200px', height: '260px' }} className="mx-auto">
          <Badge color="light" className="small position-relative float-left bg-secondary" hidden={!this.props.isSending}>⇡</Badge>
          <div ref={this.nippleContainerRef} style={{
            position: 'relative',
            width: '100%',
            height: '200px'
          }}>
          </div>
          <Alert color="info" className="small m-1 text-center" hidden={this.props.gamePadIndex == undefined}>Joystick Detected!</Alert>
        </div>
      </React.Fragment >
    );
  }
};