import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Connecting from './pages/Connecting'
import Control from './pages/Control';
import Ended from './pages/Ended';
import Init from './pages/Init';
import TaskStatsDisplay from './pages/Stats';
import * as ClientStore from './store/ClientList';
import { ApplicationState } from './store';
import { connect } from 'react-redux';

import './custom.css'

interface RouteParams { streamId: string }
type AppComponentProps = ClientStore.ClientListState
  & typeof ClientStore.actionCreators &
  RouteComponentProps<RouteParams>;

export class AppComponent extends React.PureComponent<AppComponentProps> {

  public componentDidMount() {

    this.props.requestTaskState(window.location.pathname.split('/')[2]);
  }

  public render() {

    switch (this.props.sessionState) {
      case ClientStore.SessionState.Connected:
      case ClientStore.SessionState.Streaming:
        return (
          <Layout>
            <Route path="/stats" component={TaskStatsDisplay} />
            <Route path="/stream/:streamId/init" component={Init} />
            <Route path="/stream/:streamId/live" component={Control} />
          </Layout>
        );
      case ClientStore.SessionState.Init:
      case ClientStore.SessionState.Connecting:
        return (
          <Layout>
            <Route path="/stats" component={TaskStatsDisplay} />
            <Route path="/stream/:streamId/init" component={Init} />
            <Route path="/stream/:streamId/live" component={Connecting} />
          </Layout>
        );
      case ClientStore.SessionState.Disconnected:
      case ClientStore.SessionState.Rated:
        return (
          <Layout>
            <Route path="/stats" component={TaskStatsDisplay} />
            <Route path="/stream/:streamId/init" component={Init} />
            <Route path="/stream/:streamId/live" component={Ended} />
          </Layout>
        );
      default:
        return (
          <Layout>
            <Route path="/stats" component={TaskStatsDisplay} />
            <Route path="/stream/:streamId/init" component={Init} />
            <Route path="/stream/:streamId/live" component={Home} />
          </Layout>);
    }
  }
}

export default connect(
  (state: ApplicationState) => state.clientList,
  ClientStore.actionCreators
)(AppComponent as any);