import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Card, CardBody, CardTitle, CardText, CardGroup, CardImg, Input, Modal, ModalBody, Collapse } from 'reactstrap';
import { MediaPlayer } from '../components/MediaPlayer';
import { MovementPanel } from "../components/MovementPanel";
import { RobotScriptPanel } from '../components/RobotScriptPanel';
import { SpeakPanel } from '../components/SpeakPanel';
import { ApplicationState } from '../store';
import * as ClientStore from "../store/ClientList";
import * as MovementStore from "../store/Movement";
import * as RobotScriptStore from '../store/RobotScript';
import * as FlagReportStore from '../store/FlagReport';
import * as SpeakStore from '../store/Speak';
import { RouteComponentProps } from 'react-router';

declare var LiveFeedController: any;

interface RouteParams { streamId: string }

type ControlProps = RouteComponentProps<RouteParams>
  & ClientStore.ClientListState
  & MovementStore.MovementState
  & RobotScriptStore.RobotScriptState
  & FlagReportStore.FlagReportState
  & SpeakStore.SpeakState
  & typeof ClientStore.actionCreators
  & typeof MovementStore.actionCreators
  & typeof RobotScriptStore.actionCreators
  & typeof FlagReportStore.actionCreators
  & typeof SpeakStore.actionCreators;

export class Control extends React.PureComponent<ControlProps> {

  private flagReportMessage: string;
  private readonly mediaPlayerComponent: React.RefObject<MediaPlayer>;
  private reportPanelVisible: boolean = false;

  constructor(props: any) {

    super(props);
    this.flagReportMessage = '';
    this.handleWindowUnload = this.handleWindowUnload.bind(this);
    this.handleFlagReportChange = this.handleFlagReportChange.bind(this);
    this.reportContentAndDisconnect = this.reportContentAndDisconnect.bind(this);
    this.mediaPlayerComponent = React.createRef<MediaPlayer>();
  }

  public componentDidMount() {

    if (this.props.match && this.props.match.params && this.props.match.params.streamId) {

      const taskId = this.props.match.params.streamId;
      //this.props.requestConnect(taskId);

      const webSocket = new WebSocket(`wss://${window.location.href.split('/')[2]}${process.env.REACT_APP_CONNECTION_WEB_SOCKET}${taskId}`);

      webSocket.onclose = ev => {

        this.props.setDisconnect();
      };
      webSocket.onerror = ev => {

        this.props.setDisconnect();
      };

      if (this.props.connectedClient && this.props.connectedClient.telePresenceRequested) {
        LiveFeedController.init(taskId);
      }

      window.addEventListener('beforeunload', this.handleWindowUnload);
    }
  }

  public handleWindowUnload(e: BeforeUnloadEvent) {
    //if (window.location.hostname !== 'localhost')
    this.props.requestDisconnect(true);
  }

  public handleFlagReportChange(e: any) {
    this.flagReportMessage = e.target.value;
  }

  public reportContentAndDisconnect() {
    this.props.requestFlag(this.flagReportMessage);
    this.props.requestDisconnect();
  }

  public render() {

    if (!this.props.connectedClient)
      return (<React.Fragment />);

    return (
      <React.Fragment>
        <Container className="pb-5">
          <Modal isOpen={this.props.sessionState !== ClientStore.SessionState.Streaming} centered={true}>
            <ModalBody className="text-center bg-secondary text-light">
              <div className="p-2">
                <div>You are connecting to the following remote robot.</div>
                <div>Do not refresh this page during your session.</div>
                <Card className="mt-2 bg-light text-black-50">
                  <CardBody>
                    <Row>
                      {
                        this.props.connectedClient.robotImage ?
                          <Col className="col-12 col-md-6">
                            <CardImg src={this.props.connectedClient.robotImage} alt="Robot Image" />
                          </Col>
                          :
                          <React.Fragment />
                      }
                      <Col>
                        <CardTitle><strong>{this.props.connectedClient.robotName}</strong></CardTitle>
                        <CardText>{this.props.connectedClient.robotDescription}</CardText>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
              <Button color="primary" onClick={() => this.mediaPlayerComponent.current!.LoadVideo()}>Start the live stream</Button>
            </ModalBody>
          </Modal>

          <Row>
            <Col>
              <CardGroup className="mb-3 mt-3">
                <Card className="bg-secondary text-light">
                  <CardBody>
                    <CardText><strong>Your Task:</strong> {this.props.connectedClient.taskDescription}</CardText>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-8">
              <MediaPlayer ref={this.mediaPlayerComponent} {...this.props} />

              {(this.props.connectedClient.textToSpeechEnabled) ?
                <div className="mt-1">
                  <SpeakPanel {...this.props} />
                </div> :
                <React.Fragment />
              }
            </Col>
            <Col className="col-12 col-md-4">
              <MovementPanel {...this.props} />
              {
                (this.props.connectedClient.telePresenceRequested) ?
                  <div style={{ position: 'relative', left: '0', top: '0', width: '200px', height: '170px' }} className="mx-auto">
                    <video id="local_video" muted width="200" height="170"></video>
                  </div>
                  :
                  <React.Fragment />
              }
              <RobotScriptPanel {...this.props} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="mt-2" color="success" onClick={() => this.props.requestDisconnect()}>All done!</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr className="m-2 mt-5 mb-5 bg-light" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button color="warning" hidden={this.reportPanelVisible} onClick={() => {
                this.reportPanelVisible = true;
                this.forceUpdate();
              }}>Report abuse or inappropriate content</Button>
            </Col>
          </Row>
          <Collapse isOpen={this.reportPanelVisible} className="mt-1">
            <Row className="mb-3">
              <Col className="col-12 col-lg-8">
                <Input type="text" onChange={this.handleFlagReportChange} placeholder="Optionally explain why you are flagging this task for bad content" />
              </Col>
              <Col className="col-12 col-lg-4">
                <Button color="warning" onClick={() => {
                  if (window.confirm('Are you sure you wish to report this user?'))
                    this.reportContentAndDisconnect();
                }}>Report and Disconnect!</Button>
              </Col>
            </Row>
          </Collapse>
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => {
    const { clientList, movement, robotScript, flagReport, speak } = state;
    if (clientList && movement && robotScript && flagReport && speak) {
      return {
        ...clientList, ...movement, ...robotScript, ...flagReport, ...speak
      };
    }
    return {};
  },
  dispatch => ({
    ...bindActionCreators({
      ...ClientStore.actionCreators,
      ...MovementStore.actionCreators,
      ...RobotScriptStore.actionCreators,
      ...FlagReportStore.actionCreators,
      ...SpeakStore.actionCreators
    }, dispatch)
  })
)(Control as any);