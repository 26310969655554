import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Jumbotron, Button } from 'reactstrap';

const Home = () => (
  <div className="pt-2 d-flex justify-content-center">
    <p className="lead">Synthiam Exosphere</p>
  </div>
);

export default connect()(Home);
