import * as React from 'react';
import ReactDOM from "react-dom";
import * as ClientStore from '../store/ClientList';
import { Alert } from 'reactstrap';

declare var JSMpeg: any;

type MediaPlayerProps =
  ClientStore.ClientListState
  & typeof ClientStore.actionCreators;

export class MediaPlayer extends React.Component<MediaPlayerProps> {

  private readonly videoCanvasRef: React.RefObject<HTMLCanvasElement>;
  private videoPlayer: any | undefined;
  private audioPlayer: any | undefined;

  constructor(props: any) {

    super(props);
    this.videoCanvasRef = React.createRef<HTMLCanvasElement>();
    this.LoadVideo = this.LoadVideo.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onUnlocked = this.onUnlocked.bind(this);
  }

  public componentDidMount() {

    document.addEventListener('touchstart', this.onTouchStart, false);
  }

  private onUnlocked() {

    if (this.audioPlayer) {

      this.audioPlayer.volume = 1;
      document.removeEventListener('touchstart', this.onTouchStart);
    }
  }
  private onTouchStart() {

    if (this.audioPlayer) {

      this.audioPlayer.audioOut.unlock(this.onUnlocked);
      document.removeEventListener('touchstart', this.onTouchStart);
    }
  }

  public LoadVideo() {

    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {

      const videoCanvas = node.querySelector('#video-canvas');

      if (!this.props.connectedClient)
        return;

      const videoUrl = `wss://${window.location.href.split("/")[2]}${`${process.env.REACT_APP_VIDEO_WEB_SOCKET}${this.props.connectedClient.sessionGuid}/`}`;
      this.videoPlayer = new JSMpeg.Player(videoUrl, {
        videoBufferSize: 1024 * 1024 * 1,
        audio: false,
        canvas: videoCanvas,
        autoplay: true,
        disableWebAssembly: true,
        onSourceEstablished: () => setTimeout(() => {
          window.dispatchEvent(new Event('resize')); // With a delay after video has started manually all resize to fix joystick (nipplejs) position
        }, 500)
      });

      const audioUrl = `wss://${window.location.href.split("/")[2]}${`${process.env.REACT_APP_AUDIO_WEB_SOCKET}${this.props.connectedClient.sessionGuid}/`}`;
      this.audioPlayer = new JSMpeg.Player(audioUrl, {
        video: false,
        disableWebAssembly: true
      });

      this.props.requestStartStreaming();
      this.audioPlayer.audioOut.unlock(this.onUnlocked);
    }
  }

  public render() {

    return (
      <React.Fragment>
        <div className="d-flex justify-content-center w-100 mb-2 mb-md-0">
          <Alert color="secondary" className={`${(this.props.sessionState === ClientStore.SessionState.Streaming) ? 'd-none' : ''}`}>Live stream not started yet</Alert>
          <canvas ref={this.videoCanvasRef} id="video-canvas" className={`${(this.props.sessionState === ClientStore.SessionState.Streaming) ? '' : 'd-none'} w-100`}></canvas>
        </div>
      </React.Fragment >
    );
  }
};
